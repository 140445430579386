<template>
  <v-container>
    <v-row no-gutters class="padding-top">
      <v-col cols="12">
        <v-card flat class="mx-auto card-color">
          <v-card-text>
            <v-row class="text-center py-5">
              <v-col cols="12">
                <h1 class="title-primary white--text">HAVI</h1>
              </v-col>
              <v-col cols="12">
                <v-divider />
              </v-col>
              <v-col cols="12">
                <a
                  class="px-1"
                  v-for="(social, i) in socials"
                  :key="i"
                  :href="social.url"
                  target="_blank"
                >
                  <img
                    :src="`${publicPath}social/${social.title}.png`"
                    :alt="social.title"
                    width="32"
                  />
                </a>
              </v-col>
              <v-col cols="12">
                <v-divider />
              </v-col>
              <v-col cols="12">
                <a :href="`${publicPath}presskit.pdf`" target="_blank">
                  <span class="white--text">Presskit</span>
                </a>
                <br />
                <a :href="`${publicPath}stageplot_rider.pdf`" target="_blank">
                  <span class="white--text">Stage Plot | Rider Técnico</span>
                </a>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      publicPath: process.env.BASE_URL,
      init: false,
      socials: [
        {
          title: "Spotify",
          url: "https://open.spotify.com/intl-es/artist/1kWKGRzgQQ43dHcAicVViI?si=_rAHGyz7RWu6IECXTijddw",
        },
        {
          title: "Youtube",
          url: "https://youtube.com/@havi.rockss?si=9yAUXoZL3It3UtfD",
        },
        {
          title: "Tiktok",
          url: "https://www.tiktok.com/@havi.rocks?_t=8oPSN8lj3Rd&_r=1",
        },
        {
          title: "Instagram",
          url: "https://www.instagram.com/havi.rocks?igsh=bG1xaW5tamticzl4",
        },
        {
          title: "Facebook",
          url: "https://www.facebook.com/Havi.mxx?mibextid=LQQJ4d",
        },
        {
          title: "Twitter",
          url: "https://x.com/havi_rocks?s=11",
        },
        {
          title: "Email",
          url: "mailto:contacto@havi.com.mx",
        },
      ],
    };
  },
  mounted() {},
};
</script>

<style>
.padding-top {
  padding-top: 100px;
}
.card-color {
  background-color: rgba(0, 0, 0, 0) !important;
}
.title-primary {
  font-size: 90px;
  letter-spacing: 10px;
}
a {
  text-decoration: none;
}
</style>