<template>
  <v-app>
    <v-main
      :style="`background-image: url(${publicPath}bg.jpeg)`"
      class="bg-image"
    >
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
};
</script>

<style>
.bg-image {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-attachment: fixed !important;
}
</style>